import * as React from "react"

const result = ({children,lang}) => {
  const cx = lang === 'ja' ? '67c292341984082ac' : 'e92cb3b63b7078e0b'; //ここに貼り付け用コードの?cx=以下を入力
  let gcse = document.createElement('script');
  gcse.type = 'text/javascript';
  gcse.async = true;
  gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(gcse, s);
  return (
    <div class="gcse-searchresults-only"></div>
  )
}

export default result
