import React from "react"

import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import Result from '/src/components/GoogleCustomSearchResult'
import ClientOnly from '/src/components/hooks/ClientOnly'

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  
  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("検索結果")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("検索結果")}</h1>
        <ClientOnly>
          <Result lang={t('lang')} />
        </ClientOnly>
      </ContentLayout>
    </Layout>
    )
}
export default Page

export const query = graphql `
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`